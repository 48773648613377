import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { useEffect } from "react";
import { useState } from "react";
import BlogCard from "../components/blog/blogCard";
import PageNumber from "../components/blog/pageNumber";
import { Breadcrumbs } from "../components/blog/breadcrumbs";
import Seo from "../components/shared/seo";
import HeroBlog from "../components/blog/hero";
import Navbar from "../components/blog/navbar";
import Layout from "../components/shared/layout";

const BlogList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Work Sans;
  font-feature-settings: "kern", "liga", "clig", "calt";
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .resize {
    width: 100%;
    max-width: 1280px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

const ArticleContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 5% 5%;
  margin-top: 5%;
  padding: 0 5%;
`;

export default function Blog({ data, pageContext, location }) {
  const posts = data.allPrismicPdfcreatorPost.nodes;
  const [checklocation, setCheckLocation] = useState("/");
  const [page, setPage] = useState(1);
  let blogNumber = 0;

  useEffect(() => {
    let params = new URLSearchParams(document.location.search);
    let page = params.get("page"); // is the string "Jonathan"
    console.log("Page: " + page);
    if (page) setPage(page);
  }, []);
  useEffect(() => {
    setCheckLocation(window.location.pathname);
  }, []);

  return (
    <Layout>
      <BlogList>
        <Seo title={pageContext.category} />
        <HeroBlog />
        <Navbar categories={pageContext.categoryTypes} />
        <Breadcrumbs
          currentPath={["/"].concat(
            location.pathname.split("/").filter((name) => name !== "")
          )}
        />
        <div className="resize">
          <ArticleContainer>
            {posts.map((post, i) => {
              blogNumber += 1;

              return (
                <BlogCard
                  post={post}
                  author={post.data.author.document.data.name}
                  visible={
                    i <= 8 * (page - 1) - 1 || i > 8 * page - 1
                      ? "none"
                      : "flex"
                  }
                />
              );
            })}
          </ArticleContainer>
          <PageNumber
            location={checklocation}
            blogNumber={blogNumber}
            setPage={setPage}
            page={page}
          />
        </div>
      </BlogList>
    </Layout>
  );
}

export const query = graphql`
  query BlogCategoryQuery($category: String!) {
    allPrismicPdfcreatorPost(
      sort: { fields: data___publish_date, order: DESC }
      filter: { data: { category: { eq: $category } } }
    ) {
      nodes {
        data {
          url
          title
          fit_image
          thumbnail {
            gatsbyImageData
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }
  }
`;
